@mixin badge-variant($bg) {
  @include color-yiq($bg);
  background-color: $bg;

  &[href] {
    @include hover-focus {
      @include color-yiq($bg);
      text-decoration: none;
      background-color: darken($bg, 10%);
    }
  }
}
